import BaseTextComponent from "@/components/base/el/BaseTextComponent";

export default {
    name: "BaseSmall",
    mixins: [BaseTextComponent],
    data() {
        return {
            tag: 'small'
        }
    },
}
